<template>
  <div class="clients-container">

    <div class="widgets-container">
      <div class="widget">
        <h3>Total de Clientes</h3>
        <p>{{ clientCounts.total_clients }}</p>
      </div>
      <div class="widget">
        <h3>Clientes con Etiquetas</h3>
        <p>{{ clientCounts.clients_with_tags }}</p>
      </div>
      <div class="widget">
        <h3>Clientes por Etiqueta</h3>
        <ul>
          <li v-for="tag in clientCounts.tags" :key="tag.name">
            {{ tag.name }}: {{ tag.clients_count }}
          </li>
        </ul>
      </div>
    </div>

    <div class="filters">
      <input
        type="text"
        placeholder="Buscar cliente..."
        v-model="searchQuery"
        @input="filterClients"
      />
      <select v-model="selectedLine" @change="filterClients">
        <option
          v-for="line in lines"
          :key="line.id"
          :value="line.id"
        >
          Línea {{ line.name }}
        </option>
      </select>
      <select v-model="selectedTag" @change="filterClients">
        <option value="">Todas las etiquetas</option>
        <option
          v-for="tag in tags"
          :key="tag.id"
          :value="tag.id"
        >
          {{ tag.name }}
        </option>
      </select>
    </div>

    <div class="table-container">
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Nombre</th>
            <th>Número</th>
            <th>Línea</th>
            <th>Fecha de Creación</th>
            <th>Etiquetas</th>
            <th v-if="isAdmin">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="client in paginatedClients" :key="client.id">
            <td>{{ client.client_id }}</td>
            <td>{{ client.name || "Nombre no disponible" }}</td>
            <td>{{ client.phone_number }}</td>
            <td>{{ client.line_id }}</td>
            <td>{{ formatDate(client.created_at) }}</td>
            <td>
              <div class="tags-container">
                <div
                  v-for="tag in client.tags"
                  :key="tag.id"
                  class="tag"
                  :style="{ backgroundColor: tag.color }"
                >
                  {{ tag.name }}
                </div>
              </div>
            </td>
            <td>
              <div class="action-icons">
                <ion-icon
                  name="chatbubbles-outline"
                  class="chat-icon"
                  @click="goToChat(client.client_id || client.id)"
                ></ion-icon>
                <ion-icon
                  v-if="isAdmin"
                  name="trash-outline"
                  class="delete-icon"
                  @click="confirmDelete(client)"
                ></ion-icon>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="pagination">
      <button :disabled="currentPage === 1" @click="changePage(1)">
        Primera
      </button>
      <button :disabled="currentPage === 1" @click="changePage(currentPage - 1)">
        Anterior
      </button>
      <span>Página {{ currentPage }} de {{ totalPages }}</span>
      <button
        :disabled="currentPage === totalPages"
        @click="changePage(currentPage + 1)"
      >
        Siguiente
      </button>
      <button
        :disabled="currentPage === totalPages"
        @click="changePage(totalPages)"
      >
        Última
      </button>
    </div>

    <div v-if="showModal" class="modal-overlay">
      <div class="modal">
        <div class="modal-header">
          <ion-icon name="alert-circle-outline" class="modal-icon"></ion-icon>
          <h2 class="modal-title">Confirmar Eliminación</h2>
        </div>
        <div class="modal-body">
          <p>
            ¿Estás seguro de que deseas eliminar al cliente
            <span class="highlight">{{ clientToDelete.name }}</span>?
          </p>
          <p class="modal-warning">Esta acción eliminará todos los mensajes relacionados.</p>
        </div>
        <div class="modal-footer">
          <button class="modal-button delete" @click="deleteClient">
            <ion-icon name="trash-outline"></ion-icon> Sí, eliminar
          </button>
          <button class="modal-button cancel" @click="closeModal">
            <ion-icon name="close-outline"></ion-icon> Cancelar
          </button>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import api from "@/services/axios";

export default {
  name: "ClientsView",
  data() {
    return {
      clients: [],
      filteredClients: [],
      paginatedClients: [],
      lines: [],
      tags: [],
      isAdmin: false,
      searchQuery: "",
      selectedLine: "1",
      selectedTag: "",
      currentPage: 1,
      clientsPerPage: 100,
      showModal: false,
      clientToDelete: null,
      clientCounts: {
        total_clients: 0,
        clients_with_tags: 0,
        tags: [],
      },
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.filteredClients.length / this.clientsPerPage);
    },
  },
  methods: {
    goToChat(clientId) {
      if (!clientId) {
        alert("No se puede ir al chat porque el ID del cliente no es válido.");
        return;
      }

      this.$router.push({ name: "ChatView", query: { client_id: clientId } });
    },
    async fetchClients() {
      try {
        const token = localStorage.getItem("token");
        
        const response = await api.get(`/clients?token=${token}`);

        this.clients = response.data;

        this.filterClients();
      } catch (error) {
        console.error("Error al obtener los clientes:", error);
      }
    },
    async fetchLines() {
      try {
        const token = localStorage.getItem("token");
        const response = await api.get(`/lines?token=${token}`); 
        this.lines = response.data;
      } catch (error) {
        console.error("Error al obtener las líneas:", error);
      }
    },
    async fetchTags() {
      try {
        const token = localStorage.getItem("token");
        const response = await api.get(`/tags?token=${token}`);
        this.tags = response.data;
      } catch (error) {
        console.error("Error al obtener las etiquetas:", error);
      }
    },
    async fetchClientCounts() {
      try {
        const token = localStorage.getItem("token");
        const response = await api.get(`/clients/counts?token=${token}`);
        this.clientCounts = response.data;
      } catch (error) {
        console.error("Error al obtener el conteo de clientes:", error);
      }
    },
    confirmDelete(client) {
      if (!client || !client.client_id) {
        alert("El cliente proporcionado no es válido para eliminar.");
        return;
      }

      this.clientToDelete = {
        id: client.client_id,
        name: client.name
      };
      this.showModal = true;
    },
    async deleteClient() {
      if (!this.clientToDelete || !this.clientToDelete.id) {
        alert("No se pudo completar la eliminación.");
        return;
      }

      try {
        const token = localStorage.getItem("token");

        await api.delete(`/clients/${this.clientToDelete.id}?token=${token}`);

        this.clients = this.clients.filter(client => client.client_id !== this.clientToDelete.id);
        this.filterClients();

        alert("Cliente eliminado exitosamente.");
      } catch (error) {
        alert("Hubo un error al eliminar el cliente.");
      } finally {
        this.closeModal();
      }
    },
    closeModal() {
      this.showModal = false;
      this.clientToDelete = null;
    },
    filterClients() {
      this.filteredClients = this.clients.filter((client) => {
        const name = client.name ? client.name.toLowerCase() : "";
        const phone = client.phone_number ? client.phone_number.toLowerCase() : "";
        const query = this.searchQuery.toLowerCase();

        const matchesSearch = name.includes(query) || phone.includes(query);
        const matchesLine = this.selectedLine === "" || String(client.line_id) === this.selectedLine;
        const matchesTag = !this.selectedTag || client.tags.some((tag) => tag.id === this.selectedTag);

        return matchesSearch && matchesLine && matchesTag;
      });

      this.changePage(1);
    },
    changePage(page) {
      this.currentPage = page;

      const startIndex = (page - 1) * this.clientsPerPage;
      const endIndex = startIndex + this.clientsPerPage;

      this.paginatedClients = this.filteredClients.slice(startIndex, endIndex);
    },
    formatDate(date) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return new Date(date).toLocaleDateString("es-ES", options);
    },
    checkAdminRole() {
      const token = localStorage.getItem("token");
      if (token) {
        const payload = JSON.parse(atob(token.split(".")[1]));
        this.isAdmin = payload.data.role === "admin";
      }
    },
  },
  mounted() {
    this.fetchClients();
    this.fetchLines();
    this.fetchTags();
    this.fetchClientCounts();
    this.checkAdminRole();
  },
};
</script>

<style scoped>
.filters {
  display: flex;
  gap: 20px;
  margin-top: 3rem;
  margin-bottom: 20px;
  justify-content: center;
  flex-wrap: wrap;
}

.filters input {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #cbd5e0;
  border-radius: 8px;
  width: 100%;
  max-width: 300px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease;
}

.filters input:focus {
  border-color: #3182ce;
  outline: none;
}

.filters select {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #cbd5e0;
  border-radius: 8px;
  background-color: #edf2f7;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease;
}

.filters select:hover {
  border-color: #63b3ed;
}

.table-container {
  overflow-x: auto;
  margin-bottom: 20px;
}

table {
  width: 100%;
  width: 909px;
  margin-left: 40px;
  margin-right: 40px;
  border-collapse: collapse;
  background: #ffffff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

thead {
  background-color: #2b6cb0;
  color: white;
}

thead th {
  padding: 15px;
  font-size: 1rem;
  text-align: left;
}

tbody tr {
  border-bottom: 1px solid #e2e8f0;
  transition: background-color 0.3s ease;
}

tbody tr:hover {
  background-color: #edf2f7;
}

tbody td {
  padding: 15px;
  font-size: 1rem;
  color: #4a5568;
}

.action-icons {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.chat-icon, .delete-icon {
  font-size: 1.5rem;
  cursor: pointer;
  transition: color 0.3s ease;
}

.chat-icon:hover {
  color: #2b6cb0;
}

.delete-icon:hover {
  color: #c53030;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.pagination button {
  padding: 10px 15px;
  font-size: 1rem;
  border: none;
  border-radius: 8px;
  background: #3182ce;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination button:disabled {
  background: #a0aec0;
  cursor: not-allowed;
}

.pagination button:hover:not(:disabled) {
  background: #2b6cb0;
}

.pagination span {
  font-size: 1rem;
  color: #2a4365;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: #ffffff;
  border-radius: 12px;
  width: 90%;
  max-width: 450px;
  padding: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  text-align: center;
  animation: scaleUp 0.3s ease-out;
}

.modal-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.modal-icon {
  font-size: 3rem;
  color: #e53e3e;
}

.modal-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #2a4365;
  margin-top: 10px;
}

.modal-body {
  font-size: 1rem;
  color: #4a5568;
  margin-bottom: 20px;
}

.highlight {
  font-weight: bold;
  color: #e53e3e;
}

.modal-warning {
  margin-top: 10px;
  font-size: 0.9rem;
  color: #e53e3e;
  font-weight: bold;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.modal-button {
  flex: 1;
  padding: 10px 15px;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.modal-button.delete {
  background: linear-gradient(135deg, #e53e3e, #c53030);
  color: #ffffff;
}

.modal-button.delete:hover {
  background: linear-gradient(135deg, #c53030, #9b2c2c);
  box-shadow: 0 4px 10px rgba(229, 62, 62, 0.3);
}

.modal-button.cancel {
  background: linear-gradient(135deg, #edf2f7, #cbd5e0);
  color: #4a5568;
}

.modal-button.cancel:hover {
  background: linear-gradient(135deg, #cbd5e0, #a0aec0);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

@keyframes scaleUp {
  from {
    transform: scale(0.9);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.widgets-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin-bottom: 20px;
}

.widget {
  background: linear-gradient(135deg, #2b6cb0, #3182ce);
  color: #ffffff;
  border-radius: 10px;
  padding: 15px 20px;
  width: 100%;
  max-width: 250px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.widget:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.widget h3 {
  font-size: 1rem;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: bold;
}

.widget p {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
}

.widget ul {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
  font-size: 0.9rem;
}

.widget ul li {
  margin-bottom: 5px;
}

.tags-container {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}

.tag {
  padding: 5px 10px;
  border-radius: 12px;
  font-size: 0.9rem;
  color: white;
  font-weight: bold;
  text-transform: capitalize;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

</style>
