import axios from "axios";

const api = axios.create({
  baseURL: "https://metricwsback.cwp.mx",
  timeout: 5000,
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      const token = localStorage.getItem("token");
      if (!token) {
        window.location.href = "/"; 
      }
    }
    return Promise.reject(error);
  }
);

export default api;
