<template>
  <div class="lines-container">
    <div class="table-container">
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Número de Teléfono</th>
            <th>Nombre</th>
            <th>Estado</th>
            <th>Creado</th>
            <th>Actualizado</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="line in lines" :key="line.id">
            <td>{{ line.id }}</td>
            <td>{{ line.phone_number }}</td>
            <td>{{ line.name }}</td>
            <td>
              <span :class="{'status-active': line.status === 'active', 'status-inactive': line.status === 'inactive'}">
                {{ line.status }}
              </span>
            </td>
            <td>{{ formatDate(line.created_at) }}</td>
            <td>{{ formatDate(line.updated_at) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import api from "@/services/axios";

export default {
  data() {
    return {
      lines: [],
    };
  },
  methods: {
    async fetchLines() {
      try {
        const token = localStorage.getItem("token");
        const response = await api.get(`/lines?token=${token}`);
        this.lines = response.data;
      } catch (error) {
        console.error("Error al obtener las líneas:", error);
      }
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString("es-ES", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    },
  },
  mounted() {
    this.fetchLines();
  },
};
</script>

<style scoped>
.lines-container {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.table-container {
  overflow-x: auto;
}

table {
  width: 100%;
   width: 909px;
  margin-left: 40px;
  margin-right: 40px;
  border-collapse: collapse;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

thead th {
  background: #2b6cb0;
  color: white;
  padding: 15px;
  text-align: left;
  font-weight: bold;
}

tbody tr {
  border-bottom: 1px solid #e2e8f0;
}

tbody td {
  padding: 10px;
  font-size: 0.9rem;
}

.status-active {
  color: #38a169;
  font-weight: bold;
}

.status-inactive {
  color: #e53e3e;
  font-weight: bold;
}
</style>
