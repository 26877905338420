<template>
  <div class="login-background">
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="login-container">
      <div v-if="isLoading" class="loading-container">
        <div class="loading-spinner"></div>
        <p class="loading-text">Cargando...</p>
      </div>
      <form v-else @submit.prevent="handleLogin">
        <h1>Iniciar Sesión</h1>
        <div class="form-group">
          <label for="username">Usuario</label>
          <input
            type="text"
            id="username"
            v-model="username"
            placeholder="Ingresa tu usuario"
          />
        </div>
        <div class="form-group">
          <label for="password">Contraseña</label>
          <input
            type="password"
            id="password"
            v-model="password"
            placeholder="Ingresa tu contraseña"
          />
        </div>
        <div class="form-actions">
          <button type="submit">Iniciar Sesión</button>
          <p class="error" v-if="errorMessage">{{ errorMessage }}</p>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import api from "@/services/axios";

export default {
  name: "UserLogin",
  data() {
    return {
      username: "",
      password: "",
      errorMessage: null,
      isLoading: false, 
    };
  },
  methods: {
    async handleLogin() {
      this.isLoading = true;
      this.errorMessage = null;

      try {
        const response = await api.post("/login", {
          username: this.username,
          password: this.password,
        });

        const { token } = response.data;
        localStorage.setItem("token", token);
        this.$root.isAuthenticated = true;

        this.$router.replace("/metrics");
      } catch (error) {
        console.error("Error durante el login:", error);
        if (error.response && error.response.data) {
          this.errorMessage = error.response.data.message || "Error desconocido.";
        } else {
          this.errorMessage = "Ocurrió un error. Intenta nuevamente.";
        }
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.login-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #102a43;
  overflow: hidden;
  z-index: 1;
}

.loading-spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #1d4ed8;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

.loading-text {
  margin-top: 10px;
  font-size: 16px;
  color: #000000;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.circle {
  position: absolute;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  animation: float 10s infinite ease-in-out;
}

.circle:nth-child(1) {
  width: 150px;
  height: 150px;
  top: 10%;
  left: 20%;
  animation-duration: 8s;
}

.circle:nth-child(2) {
  width: 200px;
  height: 200px;
  top: 30%;
  left: 70%;
  animation-duration: 12s;
}

.circle:nth-child(3) {
  width: 120px;
  height: 120px;
  top: 50%;
  left: 40%;
  animation-duration: 10s;
}

.circle:nth-child(4) {
  width: 300px;
  height: 300px;
  bottom: 10%;
  right: 15%;
  animation-duration: 14s;
}

.circle:nth-child(5) {
  width: 100px;
  height: 100px;
  bottom: 20%;
  left: 10%;
  animation-duration: 9s;
}

.circle:nth-child(6) {
  width: 180px;
  height: 180px;
  top: 5%;
  right: 20%;
  animation-duration: 11s;
}

@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

.login-container {
  background: white;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
  z-index: 2;
  width: 100%;
  max-width: 350px;
  text-align: center;
}

h1 {
  color: #102a43;
  font-size: 24px;
  margin-bottom: 20px;
}

form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  font-size: 14px;
  color: #334e68;
  margin-bottom: 8px;
}

input {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #d9e2ec;
  border-radius: 6px;
  background-color: #f0f4f8;
  transition: border 0.3s;
}

input:focus {
  outline: none;
  border-color: #1d4ed8;
  background-color: #ffffff;
}

button {
  width: 80%;
  padding: 12px;
  font-size: 16px;
  background-color: #1d4ed8;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #2563eb;
}

.error {
  margin-top: 10px;
  color: #e74c3c;
  font-size: 14px;
}

.links {
  margin-top: 15px;
  font-size: 14px;
  color: #1d4ed8;
}

.links a {
  color: #1d4ed8;
  text-decoration: none;
  margin: 0 5px;
}

.links a:hover {
  text-decoration: underline;
}
</style>
