<template>
  <div id="app">
    <SidebarNavigation
      v-if="isAuthenticated"
      :is-collapsed="isSidebarCollapsed"
      @toggleSidebar="handleSidebarToggle"
    />

    <div
      class="content"
      :class="{ 'content-collapsed': isSidebarCollapsed }"
      v-if="isAuthenticated"
    >
      <router-view />
    </div>
    <router-view v-else />
  </div>
</template>

<script>
import SidebarNavigation from "@/components/SidebarNavigation.vue";

export default {
  name: "App",
  components: {
    SidebarNavigation,
  },
  data() {
    return {
      isAuthenticated: this.checkTokenValidity(),
      isSidebarCollapsed: false,
    };
  },
  methods: {
    checkTokenValidity() {
      const token = localStorage.getItem("token");

      if (!token) {
        return false;
      }

      try {
        const payload = JSON.parse(atob(token.split(".")[1]));

        const expiresAt = new Date(payload.exp * 1000);
        const now = new Date();

        if (!payload.exp || expiresAt < now) {
          localStorage.removeItem("token");
          this.logout();
          return false;
        }

        return true;
      } catch {
        return false;
      }
    },
    logout() {
      localStorage.removeItem("token");
      this.isAuthenticated = false;
      this.$router.push("/");
    },
  },
  watch: {
    $route() {
      this.isAuthenticated = this.checkTokenValidity();
    },
  },
  mounted() {
    this.isAuthenticated = this.checkTokenValidity();
  },
};
</script>

<style>
#app {
  display: flex;
  height: 100vh; 
  overflow: hidden;
}

.content {
  flex: 1;
  height: 100vh; 
  overflow-y: auto;
  background: #ecf0f1;
  transition: margin-left 0.3s ease, padding 0.3s ease;
}

.content-collapsed {
  margin-left: 80px;
}

.content:not(.content-collapsed) {
  margin-left: 250px;
}

.app-login {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #102a43;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}
</style>
