<template>
  <aside class="barra-lateral">
    <div class="nombre-pagina">
      <ion-icon name="cloud-outline" class="main-icon"></ion-icon>
      <span>DataScape</span>
    </div>

    <nav class="navegacion">
      <ul>
        <li v-for="item in filteredNavigationItems" :key="item.name">
          <router-link
            :to="item.route"
            class="navegacion-enlace"
            :class="{ active: $route.path === item.route }"
          >
            <ion-icon :name="item.icon"></ion-icon>
            <span>{{ item.name }}</span>
          </router-link>
        </li>
      </ul>
    </nav>

    <div class="cerrar-sesion">
      <button @click="logout">
        <ion-icon name="log-out-outline"></ion-icon>
        <span>Cerrar Sesión</span>
      </button>
    </div>
  </aside>
</template>

<script>
export default {
  name: "SidebarNavigation",
  data() {
    return {
      navigationItems: [
        { name: "Métricas Generales", icon: "stats-chart-outline", route: "/metrics" },
        { name: "Métricas por Línea", icon: "analytics-outline", route: "/metrics-line" },
        { name: "Clientes", icon: "people-outline", route: "/clients" },
        { name: "Chat", icon: "chatbubbles-outline", route: "/chat" },
        { name: "Usuarios", icon: "person-outline", route: "/users", requiresAdmin: true },
        { name: "Líneas", icon: "call-outline", route: "/lines", requiresAdmin: true },
      ],
    };
  },
  computed: {
    filteredNavigationItems() {
      const isAdmin = this.checkAdminRole();
      return this.navigationItems.filter(
        (item) => !item.requiresAdmin || (item.requiresAdmin && isAdmin)
      );
    },
  },
  methods: {
    checkAdminRole() {
      const token = localStorage.getItem("token");
      if (token) {
        const payload = JSON.parse(atob(token.split(".")[1]));
        return payload.data.role === "admin";
      }
      return false;
    },
    logout() {
      localStorage.removeItem("token");
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.barra-lateral {
  width: 250px;
  height: 100vh;
  background: #001f3f; 
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0; 
  position: fixed; 
  top: 0;
  left: 0;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
}

.nombre-pagina {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  padding: 10px 0;
  border-bottom: 1px solid #00509e;
}

.nombre-pagina ion-icon {
  font-size: 30px;
  margin-right: 10px;
}

.nombre-pagina span {
  font-size: 20px;
  font-weight: bold;
}

.navegacion ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.navegacion li {
  margin: 0;
}

.navegacion-enlace {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  color: white;
  padding: 15px 20px;
  transition: background-color 0.3s ease, transform 0.2s;
  border-radius: 5px;
  margin: 5px 10px;
}

.navegacion-enlace:hover,
.navegacion-enlace.active {
  background: #00509e; 
  transform: scale(1.05);
}

.navegacion ion-icon {
  font-size: 20px;
  margin-right: 10px;
}

.cerrar-sesion {
  margin-bottom: 10px;
  margin-left: 10px;
  text-align: center;
}

.cerrar-sesion button {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  color: white;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s;
}

.cerrar-sesion button:hover {
  background: #ff4b5c;
  transform: scale(1.05);
}

.cerrar-sesion ion-icon {
  font-size: 20px;
  margin-right: 10px;
}

html,
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}
</style>
