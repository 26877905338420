<template>
  <div class="privacy-policy">
    <h1>Política de Privacidad</h1>
    <div class="grid-container">
      <section>
        <h2>1. Información Recopilada</h2>
        <p>
          Recopilamos información personal necesaria para la gestión de usuarios, 
          incluyendo nombres, roles, y credenciales de acceso. Toda la información 
          se almacena de forma segura y cumple con las normativas legales vigentes.
        </p>
      </section>
      <section>
        <h2>2. Uso de la Información</h2>
        <p>
          La información recopilada se utiliza únicamente para fines administrativos 
          y operativos dentro de la empresa. No compartimos esta información con 
          terceros, salvo cuando sea requerido por ley.
        </p>
      </section>
      <section>
        <h2>3. Seguridad de los Datos</h2>
        <p>
          Implementamos medidas de seguridad técnicas y organizativas para proteger 
          la información contra accesos no autorizados, pérdida o alteración. Los 
          datos sensibles están encriptados tanto en tránsito como en reposo.
        </p>
      </section>
      <section>
        <h2>4. Derechos de los Empleados</h2>
        <p>
          Los empleados tienen derecho a acceder, actualizar o eliminar su información 
          personal bajo solicitud. Para ejercer estos derechos, por favor, contacte al 
          departamento de TI.
        </p>
      </section>
      <section>
        <h2>5. Contacto</h2>
        <p>
          Si tiene preguntas o inquietudes sobre esta Política de Privacidad, puede 
          comunicarse con nosotros a través del correo electrónico oficial de la empresa.
        </p>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy",
};
</script>

<style scoped>
.privacy-policy {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #f4f7fa;
  font-family: 'Arial', sans-serif;
  color: #333;
  overflow: hidden;
}

.privacy-policy h1 {
  font-size: 1.8rem;
  margin-bottom: 20px;
  text-align: center;
  color: #2a6ebb;
  margin-top: -5rem;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr); 
  grid-gap: 20px;
  width: 90%;
  max-width: 1300px;
  height: 75%;
}

.grid-container section {
  background-color: #ffffff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.grid-container h2 {
  font-size: 1.1rem;
  margin-bottom: 10px;
  color: #444;
  border-bottom: 2px solid #2a6ebb;
  display: inline-block;
  padding-bottom: 5px;
}

.grid-container p {
  font-size: 0.95rem;
  margin: 0;
  line-height: 1.4;
}

.grid-container section:nth-child(5) {
  grid-column: span 2; 
}

@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(5, auto);
    height: auto;
  }

  .grid-container section:nth-child(5) {
    grid-column: span 1;
  }

  .privacy-policy h1 {
    font-size: 1.5rem;
  }
}
</style>

