<template>
  <div :class="['chat-container', { fullscreen: isFullscreen }]">

    <div class="clients-sidebar">
      <div class="sidebar-header">
        <ion-icon
          :name="isFullscreen ? 'contract-outline' : 'expand-outline'"
          class="fullscreen-icon"
          @click="toggleFullscreen"
        ></ion-icon>

        <ion-icon
          :name="isMultiSelectActive ? 'close-circle-outline' : 'checkbox-outline'"
          class="multi-select-icon"
          @click="toggleMultiSelect"
          title="Seleccionar múltiples clientes"
        ></ion-icon>

        <ion-icon
          name="pricetags-outline"
          class="tags-icon"
          @click="openTagsModal"
          title="Gestionar etiquetas"
        ></ion-icon>
      </div>
      <div class="clients-search">
        <select v-model="selectedLine" @change="fetchClients" class="line-filter">
          <option v-for="line in lines" :key="line.id" :value="line.id">
            {{ line.name }}
          </option>
        </select>
        <input
          type="text"
          v-model="searchQuery"
          placeholder="Buscar contactos..."
          @input="filterClients"
        />
      </div>
      <div v-if="isMultiSelectActive" class="multi-select-actions">
        <div class="buttons-container">
          <button
            class="icon-button assign-tag-button"
            :disabled="!selectedClients.length"
            @click="assignTagToSelectedClients"
            title="Asignar Etiqueta"
          >
            <ion-icon name="pricetag-outline"></ion-icon>
          </button>

          <button
            class="icon-button remove-tag-button"
            :disabled="!selectedClients.length"
            @click="removeTagsFromSelectedClients"
            title="Eliminar Etiqueta"
          >
            <ion-icon name="trash-outline"></ion-icon>
          </button>
        </div>

        <select v-model="selectedTagId" class="tag-selector">
          <option value="" disabled>Selecciona una etiqueta</option>
          <option v-for="tag in tags" :key="tag.id" :value="tag.id">
            {{ tag.name }}
          </option>
        </select>
      </div>
      <ul class="clients-list">
        <li
          v-for="client in filteredClients"
          :key="client.client_id"
          :class="{ active: client.client_id === selectedClient?.client_id, unread: !client.is_read }"
          @click="!isMultiSelectActive && selectClient(client)"
        >
          <div v-if="isMultiSelectActive" class="client-checkbox">
            <input
              type="checkbox"
              :value="client.client_id"
              v-model="selectedClients"
            />
          </div>

          <div class="client-avatar">
            <ion-icon name="person-circle-outline"></ion-icon>
          </div>

          <div class="client-info">
            <h3 class="client-name">
              {{ client.name }}
              <span v-if="!client.is_read" class="indicator">!</span>
            </h3>
            <p class="client-phone">{{ client.phone_number }}</p>
            <p class="last-message">{{ client.last_message || "Sin mensajes" }}</p>
          </div>

          <div class="message-time-and-tags">
            <span class="message-time">{{ formatDate(client.last_message_time) }}</span>
            <div v-if="client.tags && client.tags.length" class="tags-container">
              <span 
                v-for="tag in client.tags" 
                :key="tag.id" 
                class="tag tag-flag" 
                :style="{ backgroundColor: tag.color }"
              ></span>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div class="chat-window" v-if="selectedClient">
      <div class="chat-header">
        <div class="client-avatar-header">
          <ion-icon name="person-circle-outline"></ion-icon>
        </div>
        <div class="client-details">
          <h2 class="client-name">{{ selectedClient.name }}</h2>
          <p class="client-phone">{{ selectedClient.phone_number }}</p>
        </div>
      </div>
      
      <div class="chat-messages">
        <div v-for="(message, index) in messages" :key="message.id">
          <div v-if="index === 0 || !isSameDate(messages[index - 1]?.created_at, message.created_at)" class="date-label">
              {{ formatDateForLabel(message.created_at) }}
          </div>

          <div :class="['message', message.sender === 'Empleado' ? 'empleado' : 'cliente']">
              <div v-if="message.media_type === 'text' && !isGoogleMapsUrl(message.content)">
                <p class="message-content">{{ message.content || 'Mensaje vacío' }}</p>
              </div>

              <div v-else-if="message.media_type === 'location'">
                <template v-if="message.content && message.content.includes('Lat')">
                  <a
                    :href="`https://www.google.com/maps?q=${message.content.split(',')[0].split(':')[1].trim()},${message.content.split(',')[1].split(':')[1].trim()}`"
                    target="_blank"
                    class="google-maps-link"
                  >
                    📍 Ver ubicación en Google Maps
                  </a>
                </template>
                <template v-else>
                  <p class="error-message">Error: Ubicación no válida</p>
                  <p>Contenido recibido: {{ message.content }}</p>
                </template>
              </div>

              <div v-else-if="message.media_type === 'image'">
                <img 
                    :src="message.media_url" 
                    alt="Imagen" 
                    class="message-image" 
                    @error="handleMediaError(message)" 
                />
                <p v-if="message.content" class="media-caption">{{ message.content }}</p>
              </div>

              <div v-else-if="message.media_type === 'video'">
                <video controls class="message-video">
                    <source :src="message.media_url" type="video/mp4" />
                    Tu navegador no soporta reproducción de videos.
                </video>
                <p v-if="message.content" class="media-caption">{{ message.content }}</p>
              </div>

              <div v-else-if="message.media_type === 'audio'">
                <template v-if="message.media_url">
                  <div class="message-audio-container">
                    <button class="play-button" @click="toggleAudio(message.id)">
                      <ion-icon :name="isPlaying(message.id) ? 'pause-outline' : 'play-outline'"></ion-icon>
                    </button>
                    <div class="progress-bar-container">
                      <div class="progress-bar" :style="{ width: getProgress(message.id) + '%' }"></div>
                    </div>
                    <div class="audio-timestamps">
                      <span>{{ formatTime(currentTime[message.id]) }}</span>
                      /
                      <span>{{ formatTime(duration[message.id]) }}</span>
                    </div>
                  </div>
                  <audio
                    :id="`audio-${message.id}`"
                    :src="message.media_url"
                    @timeupdate="updateAudioProgress(message.id)"
                    @ended="resetAudio(message.id)"
                    class="hidden-audio-element"
                  ></audio>
                  <p v-if="message.content" class="media-caption">{{ message.content }}</p>
                </template>
                <template v-else>
                  <p class="error-message">Error: No se encontró el archivo de audio</p>
                </template>
              </div>

              <div v-else-if="message.media_type === 'document'" class="document-container">
                <a :href="message.media_url" download class="message-document">
                  📄 Descargar documento
                </a>
                <p v-if="message.content" class="media-caption">{{ message.content }}</p>
                <p class="document-details">Este archivo está disponible para descarga. Haz clic en el botón para obtenerlo.</p>
              </div>

              <small class="message-time">{{ formatDate(message.created_at) }}</small>

          </div>
        </div>
      </div>

      <div v-if="previewImage || previewVideo || previewDocument" class="preview-container">
        <img 
          v-if="previewImage" 
          :src="previewImage" 
          :class="isPreviewExpanded ? 'preview-image-large' : 'preview-image'" 
          alt="Vista previa de la imagen" 
        />
        <video 
          v-if="previewVideo" 
          :src="previewVideo" 
          :class="isPreviewExpanded ? 'preview-video-large' : 'preview-video'" 
          controls 
        ></video>
        <p v-if="previewDocument" class="preview-document">{{ previewDocument }}</p>
        <div class="preview-buttons">
          <button @click="togglePreviewSize" class="preview-toggle">
            <ion-icon :name="isPreviewExpanded ? 'contract-outline' : 'expand-outline'"></ion-icon>
          </button>
          <button @click="cancelPreview" class="preview-cancel">
            <ion-icon name="close-outline"></ion-icon>
          </button>
        </div>
      </div>

      <div class="chat-input">
        <div class="menu-toggle" @click="toggleMenu">
          <div class="icon" :class="{ open: isMenuOpen }"></div>
        </div>

        <div v-if="isMenuOpen" class="dropdown-menu">
          <div class="menu-item" @click="openFileSelector('document')">
            <ion-icon name="document-outline"></ion-icon>
            Documento
          </div>

          <div class="menu-item" @click="openFileSelector('image')">
            <ion-icon name="image-outline"></ion-icon>
            Imagen
          </div>

          <div class="menu-item" @click="openFileSelector('video')">
            <ion-icon name="videocam-outline"></ion-icon>
            Video
          </div>
        </div>

        <input
          v-model="newMessage"
          type="text"
          placeholder="Escribe un mensaje..."
          @keydown.enter.prevent="sendMessage"
        />
        <button @click="sendMessage" class="send-button">
          <ion-icon name="send-outline"></ion-icon>
        </button>

      </div>

    </div>

    <div v-else class="chat-placeholder">
      <p>Selecciona un cliente para ver los mensajes.</p>
    </div>

    <div v-if="isTagsModalOpen" class="tags-modal-overlay">
      <div class="tags-modal">
        <div class="modal-header">
          <h2 class="modal-title">Gestión de Etiquetas</h2>
          <button class="close-button" @click="closeTagsModal">
            <ion-icon name="close-outline"></ion-icon>
          </button>
        </div>

        <div class="modal-body">
          <div v-if="!isCreatingTag" class="tags-list">
            <div v-for="tag in tags" :key="tag.id" class="tag-item">
              <div class="tag-icon" :style="{ backgroundColor: tag.color }">
                <ion-icon name="pricetag-outline"></ion-icon>
              </div>
              <div class="tag-details">
                <p class="tag-name">{{ tag.name }}</p>
                <p class="tag-clients">{{ tag.clientCount || 0 }} clientes</p>
              </div>
              <button class="delete-tag-button" @click="deleteTag(tag.id)">
                <ion-icon name="trash-outline"></ion-icon>
              </button>
            </div>
            <button class="add-tag-button" @click="toggleCreateTag">
              <ion-icon name="add-circle-outline"></ion-icon> Agregar Etiqueta
            </button>
          </div>

          <div v-else class="tag-form">
            <div class="tag-preview">
              <div class="tag-icon" :style="{ backgroundColor: newTagColor }">
                <ion-icon name="pricetag-outline"></ion-icon>
              </div>
              <input
                v-model="newTagName"
                type="text"
                placeholder="Nombre de la etiqueta"
                class="tag-input"
              />
            </div>
            <div class="form-actions">
              <button class="save-tag-button" @click="createTag">Guardar</button>
              <button class="cancel-tag-button" @click="toggleCreateTag">Cancelar</button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  
</template>

<script>
import api from "@/services/axios";

export default {
  props: {
    client_id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      lines: [],
      clients: [], 
      filteredClients: [], 
      messages: [], 
      selectedLine: "", 
      selectedClient: null, 
      isFullscreen: false, 
      searchQuery: "", 
      newMessage: "",
      isMenuOpen: false, 
      selectedFile: null, 
      selectedMediaType: "text", 
      previewImage: null,
      previewVideo: null,
      isPreviewExpanded: false,
      isTagsModalOpen: false,
      isCreatingTag: false,
      newTagName: "",
      tags: [],
      isMultiSelectActive: false,
      selectedClients: [],
      selectedTagId: null,
      pollingInterval: null,
      currentTime: {}, 
      duration: {},  
      playingAudioId: null,
    };
  },
  methods: {
    isSameDate(date1, date2) {
      const d1 = new Date(date1);
      const d2 = new Date(date2);
      return (
        d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getDate() === d2.getDate()
      );
    },
    formatDate(date) {
      return date
        ? new Date(date).toLocaleTimeString("es-ES", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: false, 
          })
        : "N/A";
    },
    formatDateForLabel(date) {
      const adjustedDate = new Date(date);
      return adjustedDate.toLocaleDateString("es-ES", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    },
    async fetchLines() {
      try {
        const token = localStorage.getItem("token");
        const response = await api.get(`/lines?token=${token}`); 
        this.lines = response.data;

        if (this.lines.length > 0) {
          this.selectedLine = this.lines[0].id;
          this.fetchClients(); 
        } else {
          this.selectedLine = null; 
        }
      } catch (error) {
        console.error("Error al obtener líneas:", error);
      }
    },
    async fetchClients() {
      try {
        const token = localStorage.getItem("token");
        const response = await api.get("/chat/clients", { params: { token } });

        this.clients = response.data.map((client) => ({
          ...client,
        }));

        this.filteredClients = this.clients.sort((a, b) => {
          if (a.is_read === b.is_read) {
            return new Date(b.last_message_time) - new Date(a.last_message_time); 
          }
          return a.is_read ? 1 : -1; 
        });

        this.filterClients();
      } catch (error) {
        console.error("Error al obtener clientes:", error);
      }
    },
    async fetchClientData() {
      try {
        const token = localStorage.getItem("token");
        const response = await api.get(`/clients/${this.client_id}?token=${token}`);
        this.selectedClient = response.data;
      } catch (error) {
        console.error("Error al obtener los datos del cliente:", error);
      }
    },
    async fetchMessages(clientId) {
      try {
        const token = localStorage.getItem("token");
        const response = await api.get("/chat/messages", {
          params: { client_id: clientId, token },
        });
        this.messages = response.data.messages;
      } catch (error) {
        console.error("Error al obtener mensajes:", error);
      }
    },
    startPolling(clientId) {
      if (this.pollingInterval) {
        clearInterval(this.pollingInterval);
      }

      this.fetchClients();
      if (clientId) {
        this.fetchMessages(clientId);
      }

      this.pollingInterval = setInterval(() => {
        this.fetchClients();
        if (clientId) {
          this.fetchMessages(clientId);
        }
      }, 4500);
    },

    stopPolling() {
      if (this.pollingInterval) {
        clearInterval(this.pollingInterval);
        this.pollingInterval = null;
      }
    },

    beforeDestroy() {
      this.stopPolling();
    },
    handleMediaError(message) {
      console.error(`Error al cargar el recurso multimedia: ${message.media_url}`);
    },
    async selectClient(client) {
      this.selectedClient = client;
      this.activeClientActions = null;

      try {
        if (client?.client_id) {
          const token = localStorage.getItem("token");
          await api.post(`/chat/mark-as-read?token=${token}`, {
            client_id: client.client_id,
          });
          client.is_read = true;
          this.startPolling(client.client_id);
        }
      } catch (error) {
        console.error("Error al marcar como leído:", error);
      }
    },
    filterClients() {
      const query = this.searchQuery.toLowerCase().trim();

      this.filteredClients = this.clients.filter((client) => {
        const normalizeString = (str) =>
          str
            ? str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()
            : "";

        const name = normalizeString(client.name);
        const phone = client.phone_number ? client.phone_number.toLowerCase() : "";

        return (
          name.includes(normalizeString(query)) || phone.includes(query)
        );
      });
    },
    getMessageContent(message) {
        if (message.media_type && message.media_type !== "text") {
        const mediaLabel = `${message.media_type.charAt(0).toUpperCase()}${message.media_type.slice(1)} enviado`;
        return message.content ? `${mediaLabel}: ${message.content}` : mediaLabel;
      }
      return message.content;
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleFullscreen() {
      this.isFullscreen = !this.isFullscreen;
    },
    async sendMessage() {
      if (!this.selectedClient?.client_id || !this.selectedLine) {
        return;
      }

      if (!this.newMessage.trim() && !this.selectedFile) {
        return;
      }

      try {
        const token = localStorage.getItem("token");

        if (!this.selectedFile) {
          await api.post(`/chat/send-message?token=${token}`, {
            client_id: this.selectedClient.client_id,
            line_id: this.selectedLine,
            content: this.newMessage.trim(),
            media_type: "text",
          });
        } else {
          const formData = new FormData();
          formData.append("client_id", this.selectedClient.client_id);
          formData.append("line_id", this.selectedLine);
          formData.append("media_type", this.selectedMediaType);
          formData.append("media_url", this.selectedFile);
          formData.append("content", this.newMessage.trim());

          await api.post(`/chat/send-message?token=${token}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
        }

        this.newMessage = "";
        this.cancelPreview();
      } catch (error) {
        console.error("Error al enviar mensaje:", error.response?.data || error.message);
      }
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    selectOption(option) {
      this.isMenuOpen = false;
      console.log(`Seleccionaste la opción: ${option}`);
    },
    async openFileSelector(type) {
      this.selectedMediaType = type; 
      this.isMenuOpen = false;
      const fileInput = document.createElement("input");
      fileInput.type = "file";

      if (type === "image") {
        fileInput.accept = "image/*";
      } else if (type === "video") {
        fileInput.accept = "video/*";
      } else if (type === "document") {
        fileInput.accept = ".pdf,.doc,.docx,.xls,.xlsx";
      }

      fileInput.click();

      fileInput.onchange = (e) => {
        this.selectedFile = e.target.files[0];

        if (type === "image") {
          this.previewImage = URL.createObjectURL(this.selectedFile);
        } else if (type === "video") {
          this.previewVideo = URL.createObjectURL(this.selectedFile);
        } else if (type === "document") {
          this.previewDocument = this.selectedFile.name;
        }
      };
    },
    isGoogleMapsUrl(url) {
      const regex = /^https:\/\/(www\.)?google\.com\/maps\/.+$/;
      return regex.test(url);
    },
    cancelPreview() {
      this.selectedFile = null;
      this.previewImage = null;
      this.previewVideo = null;
      this.previewDocument = null;
      this.selectedMediaType = "text";
      this.isPreviewExpanded = false;
    },
    togglePreviewSize() {
      this.isPreviewExpanded = !this.isPreviewExpanded;
    },
    openTagsModal() {
      this.isTagsModalOpen = true;
      this.fetchTags(); 
    },
    closeTagsModal() {
      this.isTagsModalOpen = false;
    },
    toggleCreateTag() {
      this.isCreatingTag = !this.isCreatingTag;
      this.newTagName = "";
      this.newTagColor = this.generateUniqueColor();
    },
    generateUniqueColor() {
      const colors = [
        "#ff5733", "#33ff57", "#5733ff", "#ff33a8", "#33fff7",
        "#f7ff33", "#c0392b", "#8e44ad", "#16a085", "#f39c12",
      ];
      const usedColors = this.tags.map(tag => tag.color);
      return colors.find(color => !usedColors.includes(color)) || "#dddddd";
    },
    async fetchTags() {
      try {
        const token = localStorage.getItem("token");
        const response = await api.get(`/tags?token=${token}`);
        this.tags = response.data.map((tag) => ({
          ...tag,
          clientCount: tag.client_count || 0,
        }));
      } catch (error) {
        console.error("Error al obtener etiquetas:", error);
      }
    },
    async createTag() {
      if (!this.newTagName.trim()) {
        alert("El nombre de la etiqueta no puede estar vacío.");
        return;
      }

      try {
        const token = localStorage.getItem("token"); 
        const response = await api.post(`/tags?token=${token}`, {
          name: this.newTagName,
          color: this.newTagColor,
        });
        alert(response.data.message);
        this.fetchTags();
        this.toggleCreateTag();
      } catch (error) {
        console.error("Error al crear etiqueta:", error.response?.data || error.message);
      }
    },
    async deleteTag(tagId) {
      if (!confirm("¿Estás seguro de eliminar esta etiqueta?")) return;

      try {
        const token = localStorage.getItem("token");
        await api.delete(`/tags/${tagId}?token=${token}`);
        this.tags = this.tags.filter((tag) => tag.id !== tagId);
      } catch (error) {
        console.error("Error al eliminar etiqueta:", error);
      }
    },
    toggleMultiSelect() {
      this.isMultiSelectActive = !this.isMultiSelectActive;
      this.selectedClients = [];
    },
    async assignTagToSelectedClients() {
      if (!this.selectedTagId) {
        alert("Por favor, selecciona una etiqueta.");
        return;
      }

      try {
        const token = localStorage.getItem("token");
        const response = await api.post(`/clients/tags?token=${token}`, {
          client_ids: this.selectedClients, 
          tag_id: this.selectedTagId,
        });
        alert(response.data.message);

        this.fetchClients(); 
        this.toggleMultiSelect(); 
      } catch (error) {
        console.error("Error al asignar etiquetas:", error.response?.data || error.message);
      }
    },
    async removeTagsFromSelectedClients() {
      if (!this.selectedClients.length) {
        alert("Selecciona al menos un cliente.");
        return;
      }

      if (!confirm("¿Estás seguro de eliminar las etiquetas de los clientes seleccionados?")) {
        return;
      }

      try {
        const token = localStorage.getItem("token");
        const response = await api.delete(`/clients/tags?token=${token}`, {
          data: { client_ids: this.selectedClients },
        });
        alert(response.data.message);
        this.fetchClients(); 
        this.toggleMultiSelect(); 
      } catch (error) {
        console.error("Error al eliminar etiquetas:", error.response?.data || error.message);
      }
    },
    isPlaying(id) {
      return this.playingAudioId === id;
    },
    toggleAudio(id) {
      const audio = document.getElementById(`audio-${id}`);
      if (this.playingAudioId === id && !audio.paused) {
        audio.pause();
        this.playingAudioId = null;
      } else {
        if (this.playingAudioId) {
          const currentAudio = document.getElementById(
            `audio-${this.playingAudioId}`
          );
          currentAudio.pause();
        }
        this.playingAudioId = id;
        audio.play();
      }
    },
    updateAudioProgress(id) {
      const audio = document.getElementById(`audio-${id}`);
      this.currentTime[id] = audio.currentTime;
      this.duration[id] = audio.duration || 0;
    },
    getProgress(id) {
      return this.duration[id]
        ? (this.currentTime[id] / this.duration[id]) * 100
        : 0;
    },
    formatTime(time) {
      const minutes = Math.floor(time / 60);
      const seconds = Math.floor(time % 60).toString().padStart(2, "0");
      return `${minutes}:${seconds}`;
    },
  },
  mounted() {
    if (this.client_id) {
      this.fetchClientData();
      this.fetchMessages(this.client_id);
      this.startPolling(this.client_id);
    } 
    
    this.fetchLines();
    this.fetchTags();
  },
};
</script>

<style scoped>
.chat-container {
  display: flex;
  height: 100vh;
  background: #f4f5f7;
  font-family: 'Arial', sans-serif;
}

.chat-container.fullscreen {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background: #ffffff;
}

.clients-sidebar {
  width: 300px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #e0e0e0;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.sidebar-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #e0e0e0;
  gap: 10px;
}

.fullscreen-icon,
.multi-select-icon,
.tags-icon {
  font-size: 1.5rem;
  color: #007bff;
  cursor: pointer;
  transition: color 0.3s ease;
}

.fullscreen-icon:hover,
.multi-select-icon:hover,
.tags-icon:hover {
  color: #0056b3;
}

.clients-search {
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.line-filter {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  outline: none;
  transition: border-color 0.3s ease;
}

.line-filter:focus {
  border-color: #007bff;
}

.clients-search input {
  width: 95%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 25px;
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.05);
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.clients-search input:focus {
  border-color: #007bff;
  outline: none;
}

.clients-list {
  flex: 1;
  list-style: none;
  padding: 0;
  margin: 0;
  overflow-y: auto;
}

.clients-list li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #f0f0f0;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  position: relative;
}

.clients-list li:hover {
  background-color: #d0e7ff;
  transform: scale(1.001);
}

.clients-list li.active {
  background-color: #eaf6ff;
  border-left: 4px solid #007bff;
}

.clients-list li.unread {
  background-color: #e7f3ff; 
  border-left: 4px solid #28a745;
  font-weight: bold;
}

.clients-list li.unread:hover {
  background-color: #d0e7ff; 
}

.clients-list .indicator {
  background-color: #28a745;
  color: white;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  font-size: 12px;
  font-weight: bold;
}

.message-time-and-tags {
  display: flex;
  flex-direction: column;
  align-items: flex-end; 
  gap: 4px;
}

.client-avatar {
  font-size: 2.5rem;
  color: #007bff;
  margin-right: 10px;
}

.client-info {
  flex: 1;
  overflow: hidden;
}

.tags-container {
  display: flex;
  gap: 4px;
}

.tag-flag {
  width: 18px;
  height: 12px;
  border-radius: 0 3px 3px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease;
}

.client-info h3 {
  margin: 0;
  font-size: 1.1rem;
  font-weight: 600;
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.client-info .client-phone {
  font-size: 0.9rem;
  color: #666;
  margin: 2px 0;
}

.client-info .last-message {
  font-size: 0.85rem;
  color: #999;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.client-checkbox {
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.message-time {
  font-size: 0.8rem;
  color: #bbb;
  margin-left: auto;
}

.chat-window {
  width: 728px;
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
}

.chat-header {
  display: flex;
  align-items: center;
  padding: 15px;
  background: #007bff;
  color: #fff;
  border-bottom: 1px solid #0056b3;
}

.client-avatar-header {
  font-size: 3rem;
  color: #fff;
  margin-right: 15px;
}

.client-details h2 {
  margin: 0;
  font-size: 1.2rem;
  font-weight: 600;
}

.client-details p {
  margin: 0;
  font-size: 0.9rem;
  color: #e0e0e0;
}

.chat-messages {
  padding: 15px;
  overflow-y: auto;
  position: relative;
}

.date-label {
  max-width: 100px;
  text-align: center;
  margin: 10px auto;
  font-size: 0.9rem;
  font-weight: bold;
  color: #555;
  background-color: #f1f1f1;
  padding: 5px 10px;
  border-radius: 12px;
  display: block; 
  clear: both;
}

.message {
  display: inline-block;
  max-width: 70%;
  min-width: 10%;
  padding: 10px 15px;
  border-radius: 15px;
  font-size: 0.95rem;
  line-height: 1.4;
  word-wrap: break-word;
  word-break: break-word;
  margin-bottom: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.3s ease;
  clear: both;
  margin-bottom: 10px;
}

.message.cliente {
  background: #ffffff;
  border: 1px solid #ddd;
  float: left;
  margin-right: auto;
}

.message.empleado {
  background: #007bff;
  color: #fff;
  float: right; 
  margin-left: auto; 
}

.message-content {
  margin: 0;
  word-wrap: break-word;
  word-break: break-word;
}

.message-time {
  font-size: 0.8rem;
  color: #bbb;
  margin-top: 5px;
  text-align: right;
}

.media-content {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.media-type {
  font-weight: bold;
  color: #333;
  font-size: 1rem;
  display: flex;
  align-items: center;
}

.media-type::before {
  content: attr(data-emoji);
  margin-right: 5px;
}

.message-text {
  font-size: 0.9rem;
  color: #333;
}

.message-audio-container {
  display: flex;
  align-items: center;
  gap: 10px;
  background: #f9f9f9;
  padding: 10px 15px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin: 10px 0;
}

.play-button {
  background: #007bff;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background 0.3s ease;
}

.play-button:hover {
  background: #0056b3;
}

.play-button ion-icon {
  font-size: 1.5rem;
}

.progress-bar-container {
  flex: 1;
  height: 8px;
  background: #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}

.progress-bar {
  height: 100%;
  background: #007bff;
  width: 0;
  transition: width 0.3s ease;
}

.audio-timestamps {
  font-size: 0.9rem;
  color: #666;
}

.google-maps-link {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}
.google-maps-link:hover {
  text-decoration: underline;
}

.chat-placeholder {
  width: 728px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999;
  font-size: 1rem;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.chat-input {
  display: flex;
  align-items: center;
  padding: 8px;
  border-top: 1px solid #ddd;
  background: #f9f9f9;
  position: relative; 
  bottom: 0;
  width: 97%;
  z-index: 10;
}

.chat-input input {
  flex: 1;
  height: 36px; 
  border: 1px solid #ddd;
  border-radius: 18px;
  padding: 4px 12px; 
  font-size: 0.9rem;
  outline: none;
  transition: border-color 0.3s ease;
}

.chat-input input:focus {
  border-color: #007bff;
}

.chat-input .send-button {
  background: #007bff;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 36px; 
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.chat-input .send-button:hover {
  background: #0056b3;
}

.chat-input .send-button ion-icon {
  font-size: 1.2rem;
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 15px;
  display: flex;
  flex-direction: column;
}

.menu-toggle {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #007bff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin-right: 10px;
}

.menu-toggle:hover {
  background-color: #0056b3;
  transform: scale(1.1);
}

.menu-toggle .icon {
  width: 18px;
  height: 2px;
  background-color: #fff;
  position: relative;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.menu-toggle .icon::before,
.menu-toggle .icon::after {
  content: "";
  width: 18px;
  height: 2px;
  background-color: #fff;
  position: absolute;
  left: 0;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.menu-toggle .icon::before {
  top: -6px;
}

.menu-toggle .icon::after {
  top: 6px;
}

.menu-toggle .icon.open {
  background-color: transparent;
}

.menu-toggle .icon.open::before {
  transform: rotate(45deg);
  top: 0;
}

.menu-toggle .icon.open::after {
  transform: rotate(-45deg);
  top: 0;
}

.dropdown-menu {
  position: absolute;
  bottom: 60px;
  left: 10px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  padding: 10px;
  z-index: 100;
  animation: fadeIn 0.3s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.menu-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 8px;
}

.menu-item:hover {
  background-color: #f0f0f0;
}

.menu-item ion-icon {
  font-size: 1.2rem;
  color: #007bff;
}

.message-video {
  max-width: 90%; 
  max-height: 320px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  object-fit: cover;
}

.document-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px;
  background: #f4f4f4;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
}

.message-document {
  text-decoration: none;
  color: #007bff;
  font-weight: bold;
  padding: 8px 12px;
  border: 1px solid #007bff;
  border-radius: 5px;
  background-color: #ffffff;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.message-document:hover {
  background-color: #007bff;
  color: #ffffff;
}

.document-details {
  font-size: 0.9rem;
  color: #555;
  margin-top: 5px;
}

.message-image {
  max-width: 80%;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.media-caption {
  font-size: 0.9rem;
  color: #ffffff;
  margin-top: 5px;
}

.message-audio {
  width: 100%;
  border-radius: 8px;
}

.google-maps-link {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.google-maps-link:hover {
  text-decoration: underline;
  color: #0056b3;
}

.message-time {
  font-size: 0.8rem;
  color: #bbb;
  margin-top: 5px;
  text-align: right;
}

.preview-container {
  display: flex;
  align-items: center;
  margin: 10px 0;
  padding: 10px;
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 10px;
  position: relative;
  max-width: 70%;
}

.preview-content {
  display: flex;
  align-items: center;
}

.preview-image {
  max-width: 100px;
  max-height: 100px;
  border-radius: 8px;
}

.preview-image-large {
  max-width: 300px;
  max-height: 300px;
  border-radius: 8px;
}

.preview-video {
  max-width: 100px;
  max-height: 100px;
  border-radius: 8px;
}

.preview-video-large {
  max-width: 300px;
  max-height: 300px;
  border-radius: 8px;
}

.preview-document {
  font-size: 1rem;
  color: #333;
}

.preview-toggle { 
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 1.2rem;
  margin-left: 10px;
}

.preview-buttons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.preview-toggle,
.preview-cancel {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: #007bff;
  transition: color 0.3s ease, transform 0.3s ease;
}

.preview-toggle:hover,
.preview-cancel:hover {
  color: #0056b3;
  transform: scale(1.2);
}

.preview-cancel {
  color: #ec1a03;
}

.preview-cancel:hover {
  color: #8d0f01;
}

.tags-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.tags-modal {
  background: white;
  border-radius: 8px;
  width: 450px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  max-height: 80vh; 
  display: flex;
  flex-direction: column; 
}

.modal-body {
  flex: 1;
  overflow-y: auto;
  padding-right: 10px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modal-title {
  font-size: 18px;
  font-weight: bold;
}

.close-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: #ff4d4f;
}

.tags-list .tag-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  border: 1px solid #f0f0f0;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.tags-list .tag-item:hover {
  background-color: #f9f9f9;
}

.tag-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.tag-details {
  flex: 1;
  margin-left: 15px;
}

.tag-name {
  font-weight: bold;
  margin: 0;
}

.tag-clients {
  font-size: 0.85em;
  color: gray;
  margin: 0;
}

.delete-tag-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: #ff4d4f;
  transition: color 0.3s;
}

.delete-tag-button:hover {
  color: #d32f2f;
  scale: 1.1;
}

.add-tag-button {
  display: flex;
  align-items: center;
  gap: 5px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 10px;
}

.add-tag-button:hover {
  background-color: #0056b3;
}

.tag-form {
  margin-top: 20px;
  flex: 1;
  overflow-y: auto; 
}

.tag-preview {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.tag-preview .tag-icon {
  width: 40px;
  height: 40px;
}

.tag-input {
  margin-left: 15px;
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-actions {
  display: flex;
  justify-content: space-between;
}

.save-tag-button,
.cancel-tag-button {
  background: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.cancel-tag-button {
  background: #6c757d;
  font-size: 10px;
}

.save-tag-button:hover {
  background-color: #0056b3;
}

.cancel-tag-button:hover {
  background-color: #495057;
}

.multi-select-actions {
  display: flex;
  flex-direction: column; 
  align-items: flex-start; 
  gap: 10px;
  margin: 15px 0;
}

.buttons-container {
  display: flex;
  gap: 10px;
}

.tag-selector {
  padding: 8px;
  border-radius: 6px;
  border: 1px solid #ddd;
  font-size: 0.9rem;
  background-color: #ffffff;
  outline: none;
  transition: border-color 0.3s ease;
  width: 100%; 
}

.tag-selector:focus {
  border-color: #007bff;
}

.icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f0f0f0;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.icon-button:hover {
  background-color: #e0e0e0;
  transform: scale(1.1);
}

.icon-button:disabled {
  background-color: #f9f9f9;
  cursor: not-allowed;
  opacity: 0.5;
}

.assign-tag-button ion-icon {
  color: #007bff;
}

.remove-tag-button ion-icon {
  color: #ff4d4f;
}

</style>
