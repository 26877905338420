<template>
  <div class="users-container">
    <div class="toolbar">
      <button class="add-button" @click="openCreateModal">
        <ion-icon name="add-circle-outline"></ion-icon> Crear Usuario
      </button>
    </div>

    <div class="filters">
      <input
        type="text"
        v-model="searchQuery"
        placeholder="Buscar usuarios..."
        @input="filterUsers"
      />
    </div>

    <div class="table-container">
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Nombre</th>
            <th>Rol</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in filteredUsers" :key="user.id">
            <td>{{ user.id }}</td>
            <td>{{ user.name }}</td>
            <td>{{ user.role }}</td>
            <td class="actions">
              <button class="edit-button edit" @click="openEditModal(user)">
                <ion-icon name="create-outline"></ion-icon>
              </button>
              <button class="delete-button trash" @click="openConfirmModal(user)">
                <ion-icon name="trash-outline"></ion-icon>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="isConfirmModalOpen" class="modal-overlay">
      <div class="modal modal-confirm">
        <div class="modal-header">
          <ion-icon class="modal-icon" name="alert-circle-outline"></ion-icon>
          <h2 class="modal-title">¡Confirmación de Eliminación!</h2>
        </div>
        <div class="modal-body">
          <p class="modal-text">
            ¿Estás seguro de que deseas eliminar al usuario
            <span class="highlight">{{ selectedUser?.name }}</span>?
          </p>
        </div>
        <div class="modal-footer">
          <button class="modal-button delete" @click="deleteUser">
            <ion-icon name="trash-outline"></ion-icon> Eliminar
          </button>
          <button class="modal-button cancel" @click="closeConfirmModal">
            <ion-icon name="close-outline"></ion-icon> Cancelar
          </button>
        </div>
      </div>
    </div>

    <div v-if="isModalOpen" class="modal-overlay">
      <div class="modal">
        <h2>{{ modalTitle }}</h2>
        <div class="form-group">
          <label for="name">Nombre</label>
          <input
            type="text"
            id="name"
            v-model="formData.name"
            :class="{ error: errors.name }"
          />
          <p v-if="errors.name" class="error">{{ errors.name }}</p>
        </div>
        <div class="form-group" v-if="isCreating">
          <label for="password">Contraseña</label>
          <input
            type="password"
            id="password"
            v-model="formData.password"
            :class="{ error: errors.password }"
          />
          <p v-if="errors.password" class="error">{{ errors.password }}</p>
        </div>
        <div class="form-group">
          <label for="role">Rol</label>
          <select id="role" v-model="formData.role" :class="{ error: errors.role }">
            <option value="">Seleccionar Rol</option>
            <option value="admin">Admin</option>
            <option value="user">Usuario</option>
          </select>
          <p v-if="errors.role" class="error">{{ errors.role }}</p>
        </div>
        <div class="modal-actions">
          <button class="save-button" @click="saveUser">Guardar</button>
          <button class="cancel-button" @click="closeModal">Cancelar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/services/axios";

export default {
  data() {
    return {
      users: [],
      searchQuery: "",
      filteredUsers: [],
      isModalOpen: false,
      isConfirmModalOpen: false,
      selectedUser: null,
      isCreating: true,
      modalTitle: "",
      formData: { name: "", password: "", role: "" },
      errors: {},
    };
  },
  methods: {
    async fetchUsers() {
      try {
        const token = localStorage.getItem("token");
        const response = await api.get(`/users?token=${token}`); 
        this.users = response.data;
        this.filteredUsers = this.users;
      } catch (error) {
        console.error("Error al obtener usuarios:", error);
      }
    },
    filterUsers() {
      this.filteredUsers = this.users.filter((user) =>
        user.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
    openCreateModal() {
      this.isCreating = true;
      this.modalTitle = "Crear Usuario";
      this.formData = { name: "", password: "", role: "" };
      this.errors = {};
      this.isModalOpen = true;
    },
    openEditModal(user) {
      this.isCreating = false;
      this.modalTitle = "Editar Usuario";
      this.formData = { ...user, password: "" };
      this.errors = {};
      this.isModalOpen = true;
    },
    openConfirmModal(user) {
      this.selectedUser = user;
      this.isConfirmModalOpen = true;
    },
    closeConfirmModal() {
      this.isConfirmModalOpen = false;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    async saveUser() {
      this.errors = this.validateForm();
      if (Object.keys(this.errors).length) return;

      try {
        const token = localStorage.getItem("token");
        if (this.isCreating) {
          await api.post(`/users?token=${token}`, this.formData); 
        } else {
          await api.put(`/users/${this.formData.id}?token=${token}`, this.formData); 
        }
        this.closeModal();
        this.fetchUsers();
      } catch (error) {
        console.error("Error al guardar usuario:", error);
      }
    },
    async deleteUser() {
      try {
        const token = localStorage.getItem("token");
        await api.delete(`/users/${this.selectedUser.id}?token=${token}`); 
        this.closeConfirmModal();
        this.fetchUsers();
      } catch (error) {
        console.error("Error al eliminar usuario:", error);
      }
    },
    validateForm() {
      const errors = {};
      if (!this.formData.name || this.formData.name.length < 3)
        errors.name = "El nombre debe tener al menos 3 caracteres.";
      if (this.isCreating && (!this.formData.password || this.formData.password.length < 6))
        errors.password = "La contraseña debe tener al menos 6 caracteres.";
      if (!this.formData.role) errors.role = "El rol es obligatorio.";
      return errors;
    },
  },
  mounted() {
    this.fetchUsers();
  },
};
</script>

<style scoped>
.users-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(135deg, #edf2f7, #cbd5e0);
  padding: 20px;
  box-sizing: border-box;
}

.toolbar {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.add-button {
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 8px;
  background-color: #2b6cb0;
  color: white;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-button ion-icon {
  font-size: 1.5rem;
}

.add-button:hover {
  background-color: #2c5282;
}

.filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
}

.filters input {
  flex: 1;
  padding: 10px 15px;
  font-size: 1rem;
  border: 2px solid #2c5282;
  border-radius: 8px;
  background-color: #edf2f7;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.filters input:focus {
  border-color: #3182ce;
  outline: none;
}

.table-container {
  flex: 1;
  overflow-y: auto;
  box-sizing: border-box;
}

table {
  width: 100%;
  width: 909px;
  margin-left: 40px;
  margin-right: 40px;
  border-collapse: collapse;
  background: #ffffff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

thead th {
  background-color: #2b6cb0;
  color: white;
  padding: 15px;
  font-weight: 600;
  text-align: left;
  font-size: 1rem;
}

tbody tr {
  border-bottom: 1px solid #e2e8f0;
  transition: background-color 0.3s ease;
}

tbody tr:hover {
  background-color: #edf2f7;
}

tbody td {
  padding: 15px;
  font-size: 1rem;
  color: #4a5568;
}

.actions {
  display: flex;
  gap: 10px;
}

.actions .edit-button,
.actions .delete-button {
  padding: 8px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.actions .edit-button {
  background-color: #2b6cb0;
  color: white;
}

.actions .edit-button:hover {
  background-color: #2c5282;
}

.actions .delete-button {
  background-color: #e53e3e;
  color: white;
}

.actions .delete-button:hover {
  background-color: #c53030;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: #ffffff;
  border-radius: 12px;
  width: 90%;
  max-width: 450px;
  padding: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  text-align: center;
  animation: scaleUp 0.3s ease-out;
}

.modal-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.modal-icon {
  font-size: 3rem;
  color: #e53e3e;
}

.modal-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #2a4365;
  margin-top: 10px;
}

.modal-body {
  font-size: 1rem;
  color: #4a5568;
  margin-bottom: 20px;
}

.highlight {
  font-weight: bold;
  color: #e53e3e;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.modal h2 {
  font-size: 1.6rem;
  color: #2a4365;
  font-weight: bold;
  margin-bottom: 15px;
}

.modal p {
  font-size: 1rem;
  color: #4a5568;
  margin-bottom: 20px;
}

.modal-button {
  flex: 1;
  padding: 10px 15px;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.modal-button.delete {
  background: linear-gradient(135deg, #e53e3e, #c53030);
  color: #ffffff;
}

.modal-button.delete:hover {
  background: linear-gradient(135deg, #c53030, #9b2c2c);
  box-shadow: 0 4px 10px rgba(229, 62, 62, 0.3);
}

.modal-button.cancel {
  background: linear-gradient(135deg, #edf2f7, #cbd5e0);
  color: #4a5568;
}

.modal-button.cancel:hover {
  background: linear-gradient(135deg, #cbd5e0, #a0aec0);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

@keyframes scaleUp {
  from {
    transform: scale(0.9);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.modal-actions {
  display: flex;
  justify-content: space-around;
  gap: 10px;
  margin-top: 20px;
}

.save-button,
.delete-button,
.cancel-button {
  padding: 12px 25px;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  letter-spacing: 0.5px;
}

.save-button {
  background: linear-gradient(135deg, #2b6cb0, #3182ce);
  color: white;
}

.save-button:hover {
  background: linear-gradient(135deg, #2c5282, #2b6cb0);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}

.delete-button {
  background: linear-gradient(135deg, #e53e3e, #f56565);
  color: white;
}

.delete-button:hover {
  background: linear-gradient(135deg, #c53030, #e53e3e);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}

.cancel-button {
  background: linear-gradient(135deg, #edf2f7, #e2e8f0);
  color: #4a5568;
}

.cancel-button:hover {
  background: linear-gradient(135deg, #cbd5e0, #edf2f7);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}

.cancel-button:active,
.save-button:active,
.delete-button:active {
  transform: scale(0.97);
}

.form-group {
  margin-bottom: 15px;
  text-align: left;
}

.form-group label {
  display: block;
  font-weight: 600;
  color: #4a5568;
  margin-bottom: 5px;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #cbd5e0;
  border-radius: 8px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.form-group input:focus,
.form-group select:focus {
  border-color: #3182ce;
  box-shadow: 0 0 5px rgba(49, 130, 206, 0.5);
  outline: none;
}

.form-group .error {
  font-size: 0.85rem;
  color: #e53e3e;
  margin-top: 5px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
