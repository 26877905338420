import { createRouter, createWebHistory } from "vue-router";
import UserLogin from "@/views/UserLogin.vue";
import MetricsGeneralView from "@/views/MetricsGeneralView.vue";
import MetricsLineView from "@/views/MetricsLineView.vue";
import ClientsView from "@/views/ClientsView.vue";
import ChatView from "@/views/ChatView.vue";
import UsersAdminView from "@/views/UsersAdminView.vue";
import LinesAdminView from "@/views/LinesAdminView.vue";
import PrivacyPolicy from "@/static/PrivacyPolicy.vue";
import TermsOfService from "@/static/TermsOfService.vue";

const routes = [
  { path: "/", name: "UserLogin", component: UserLogin },
  { path: "/metrics", name: "MetricsGeneralView", component: MetricsGeneralView },
  { path: "/metrics-line", name: "MetricsLineView", component: MetricsLineView },
  { path: "/clients", name: "ClientsView", component: ClientsView },
  { 
    path: "/chat", 
    name: "ChatView", 
    component: ChatView, 
    props: (route) => ({ client_id: route.query.client_id }),
  },
  { path: "/users", name: "UsersAdminView", component: UsersAdminView, meta: { requiresAdmin: true } },
  { path: "/lines", name: "LinesAdminView", component: LinesAdminView, meta: { requiresAdmin: true } },
  { path: "/privacy-policy", name: "PrivacyPolicy", component: PrivacyPolicy },
  { path: "/terms-of-service", name: "TermsOfService", component: TermsOfService },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {

  const publicPages = ["/", "/privacy-policy", "/terms-of-service"];
  const authRequired = !publicPages.includes(to.path);
  const token = localStorage.getItem("token");

  if (authRequired && !token) {
    return next("/");
  }

  if (to.meta.requiresAdmin && token) {
    try {
      const payload = JSON.parse(atob(token.split(".")[1]));

      if (payload.data.role !== "admin") {
        return next("/metrics");
      }
    } catch (error) {
      return next("/");
    }
  }

  next();
});

export default router;
