<template>
  <div class="terms-of-service">
    <h1>Términos del Servicio</h1>
    <div class="grid-container">
      <section>
        <h2>1. Uso Permitido</h2>
        <p>
          Este sistema está destinado exclusivamente para empleados de la empresa con
          fines operativos y administrativos. Cualquier uso no autorizado está
          estrictamente prohibido.
        </p>
      </section>
      <section>
        <h2>2. Acceso</h2>
        <p>
          El acceso al sistema es personal e intransferible. Los empleados son responsables
          de mantener la confidencialidad de sus credenciales de acceso y de notificar
          cualquier actividad sospechosa al departamento de TI.
        </p>
      </section>
      <section>
        <h2>3. Responsabilidades</h2>
        <p>
          Los usuarios deben utilizar la aplicación de manera ética y profesional,
          cumpliendo con las políticas de la empresa. Está prohibido manipular datos
          o intentar acceder a información no autorizada.
        </p>
      </section>
      <section>
        <h2>4. Modificaciones</h2>
        <p>
          La empresa se reserva el derecho de actualizar estos términos en cualquier
          momento. Las modificaciones serán comunicadas oportunamente.
        </p>
      </section>
      <section>
        <h2>5. Contacto</h2>
        <p>
          Para consultas relacionadas con estos términos, comuníquese con el
          departamento de TI.
        </p>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "TermsOfService",
};
</script>

<style scoped>
.terms-of-service {
  height: 100vh; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #f7fafc;
  font-family: 'Arial', sans-serif;
  color: #2d3748;
  overflow: hidden;
}

.terms-of-service h1 {
  font-size: 1.8rem;
  margin-bottom: 20px;
  text-align: center;
  color: #2b6cb0;
  margin-top: -5rem;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 20px;
  width: 90%;
  max-width: 1300px;
  height: 75%; 
}

.grid-container section {
  background-color: #ffffff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.grid-container h2 {
  font-size: 1.1rem;
  margin-bottom: 10px;
  color: #2c5282;
  border-bottom: 2px solid #2b6cb0;
  display: inline-block;
  padding-bottom: 5px;
}

.grid-container p {
  font-size: 0.95rem;
  margin: 0;
  line-height: 1.4;
}

.grid-container section:nth-child(5) {
  grid-column: span 2;
}

@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(5, auto);
    height: auto;
  }

  .grid-container section:nth-child(5) {
    grid-column: span 1;
  }

  .terms-of-service h1 {
    font-size: 1.5rem;
  }
}
</style>

