<template>
  <div class="metrics-container">

    <button class="filter-modal-button" @click="openFilterModal">
      <ion-icon name="filter-outline"></ion-icon>
    </button>

    <div class="summary">
      <div class="widget">
        <h2>Total Mensajes</h2>
        <p>{{ metrics.total_messages }}</p>
      </div>
      <div class="widget">
        <h2>Porcentaje de Respuesta</h2>
        <p>{{ metrics.response_rate.toFixed(2) }}%</p>
      </div>
      <div class="widget">
        <h2>Tiempo Promedio de Respuesta</h2>
        <p>{{ metrics.avg_response_time ? `${metrics.avg_response_time} min` : "N/A" }}</p>
      </div>
      <div class="widget">
        <h2>Mensajes Respondidos</h2>
        <p>{{ metrics.response_stats.responded || 0 }}</p>
      </div>
      <div class="widget">
        <h2>Mensajes No Respondidos</h2>
        <p>{{ metrics.response_stats.unresponded || 0 }}</p>
      </div>
    </div>

    <div v-if="showFilterModal" class="filter-modal">
      <div class="filter-modal-content unique-modal">
        <h2>Filtros</h2>
        <label for="startTime">Inicio:</label>
        <input type="date" v-model="startTime" />
        <label for="endTime">Fin:</label>
        <input type="date" v-model="endTime" />
        <label for="hour_start">Hora de inicio:</label>
        <input type="time" v-model="hour_start" />
        <label for="hour_end">Hora de fin:</label>
        <input type="time" v-model="hour_end" />
        <div class="filter-modal-actions">
          <button @click="applyFilters">
            <ion-icon name="checkmark-circle"></ion-icon> Aplicar
          </button>
          <button @click="closeFilterModal">
            <ion-icon name="close-circle"></ion-icon> Cerrar
          </button>
        </div>
      </div>
    </div>

    <div class="chart-grouping-filter">
      <label for="groupBy">Agrupar por:</label>
      <select v-model="groupBy" @change="fetchMetrics">
        <option value="hour">Hora</option>
        <option value="day">Día</option>
        <option value="week">Semana</option>
        <option value="month">Mes</option>
      </select>
    </div>

    <div class="charts">
      <div class="chart">
        <h2>Frecuencia de Mensajes</h2>
        <canvas id="frequencyChart"></canvas>
      </div>
      <div class="chart chart-distri">
        <h2>Distribución de Tipos de Mensaje</h2>
        <canvas id="mediaChart"></canvas>
      </div>
    </div>

    <div class="active-users">
      <h2>Usuarios Más Activos</h2>
      <div class="table-container">
        <table>
          <thead>
            <tr>
              <th>ID Usuario</th>
              <th>Mensajes</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in metrics.active_users" :key="user.client_id">
              <td>{{ user.client_id }}</td>
              <td>{{ user.message_count }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div v-if="filtersActive" class="applied-filters-modal" ref="filtersModal">
      <div class="modal-header" @click="toggleModal">
        <h3>Filtros Aplicados</h3>
        <ion-icon 
          :name="isCollapsed ? 'chevron-down-outline' : 'chevron-up-outline'" 
          class="toggle-icon">
        </ion-icon>
      </div>
      <div v-if="!isCollapsed" class="modal-content">
        <ul>
          <li v-if="startTime && endTime">
            <span class="filter-label">Fechas:</span> {{ startTime }} - {{ endTime }}
          </li>
          <li v-if="hour_start && hour_end">
            <span class="filter-label">Horas:</span> {{ hour_start }} - {{ hour_end }}
          </li>
          <li>
            <span class="filter-label">Agrupación:</span> {{ groupBy }}
          </li>
        </ul>
        <button class="clear-filters-button" @click="clearFilters">Limpiar Filtros</button>
      </div>
    </div>

  </div>
</template>

<script>
import api from "@/services/axios";
import Chart from "chart.js/auto";

export default {
  name: "MetricsGeneralView",
  data() {
    return {
      metrics: {
        total_messages: 0,
        response_stats: { responded: 0, unresponded: 0 },
        avg_response_time: 0,
        frequency_data: [],
        media_stats: [],
        response_rate: 0,
        active_users: [],
      },
      groupBy: "hour",
      startTime: "",
      endTime: "",
      hour_start: "",
      hour_end: "",
      frequencyChart: null,
      mediaChart: null,
      showFilterModal: false,
      filtersActive: false,
      isCollapsed: true,
    };
  },
  methods: {
    convertTo24Hour(time12h) {
      const [time, modifier] = time12h.split(" ");
      let [hours, minutes] = time.split(":");
      if (hours === "12") hours = "00";
      if (modifier === "PM") hours = parseInt(hours, 10) + 12;
      return `${hours}:${minutes}:00`;
    },
    async fetchMetrics() {
      const hourStart24 = this.hour_start || null;
      const hourEnd24 = this.hour_end || null;

      const token = localStorage.getItem("token");

      if (!token || token.length === 0) {
          console.error("Token no encontrado o inválido");
          return;
      }

      if (this.startTime && !/^\d{4}-\d{2}-\d{2}$/.test(this.startTime)) {
          console.error("Formato inválido para start_time");
          return;
      }

      if (this.endTime && !/^\d{4}-\d{2}-\d{2}$/.test(this.endTime)) {
          console.error("Formato inválido para end_time");
          return;
      }
      
      try {
          const response = await api.get(`/metrics?token=${token}`, {
              params: {
                  start_time: this.startTime,
                  end_time: this.endTime,
                  hour_start: hourStart24,
                  hour_end: hourEnd24,
                  group_by: this.groupBy,
              },
          });

          this.metrics = response.data;
          this.updateCharts();
      } catch (error) {
          console.error("Error al obtener las métricas:", error);
      }
    },
    updateCharts() {
      this.createFrequencyChart();
      this.createMediaChart();
    },
    createFrequencyChart() {
      const ctx = document.getElementById("frequencyChart").getContext("2d");
      if (this.frequencyChart) this.frequencyChart.destroy();

      const labels = this.metrics.frequency_data.map((data) => data.time_period);
      const data = this.metrics.frequency_data.map((data) => data.message_count);

      this.frequencyChart = new Chart(ctx, {
        type: "line",
        data: {
          labels,
          datasets: [
            {
              label: "Frecuencia de Mensajes",
              data,
              borderColor: "#1d4ed8",
              fill: false,
            },
          ],
        },
      });
    },
    createMediaChart() {
      const ctx = document.getElementById("mediaChart").getContext("2d");
      if (this.mediaChart) this.mediaChart.destroy();

      const labels = this.metrics.media_stats.map((stat) => stat.media_type);
      const data = this.metrics.media_stats.map((stat) => stat.count);

      this.mediaChart = new Chart(ctx, {
        type: "pie",
        data: {
          labels,
          datasets: [
            {
              data,
              backgroundColor: ["#1d4ed8", "#2563eb", "#3b82f6", "#93c5fd"],
            },
          ],
        },
      });
    },
    openFilterModal() {
      this.showFilterModal = true;
    },
    closeFilterModal() {
      this.showFilterModal = false;
    },
    toggleModal() {
      this.isCollapsed = !this.isCollapsed;
    },
    applyFilters() {
      this.fetchMetrics();
      this.filtersActive = true; 
      this.closeFilterModal();
    },
    clearFilters() {
      this.startTime = "";
      this.endTime = "";
      this.hour_start = "";
      this.hour_end = "";
      this.groupBy = "hour";
      this.filtersActive = false; 
      this.fetchMetrics(); 
    },
  },
  mounted() {
    this.fetchMetrics();
  },
};
</script>

<style scoped>
.metrics-container {
  max-width: 1200px;
  margin: 10px auto;
  padding: 20px;
}

.summary {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-around;
}

.widget {
  flex: 1 1 150px;
  background: #f7fafc;
  padding: 20px;
  border-radius: 12px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
}

.widget h2 {
  margin-bottom: 10px;
  font-size: 1.2rem;
  color: #2c5282;
}

.widget p {
  font-size: 1.8rem;
  color: #2b6cb0;
  font-weight: 600;
}

.filter-modal-button {
  background-color: #2b6cb0;
  color: white;
  padding: 12px;
  border: none;
  border-radius: 50%;
  font-size: 1.5rem;
  cursor: pointer;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
  margin-bottom: 1rem;
}

.filter-modal-button ion-icon {
  font-size: 1.5rem;
  color: white;
}

.filter-modal-button:hover {
  background-color: #2c5282;
}

.filter-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeInOverlay 0.3s ease-in-out;
}

@keyframes fadeInOverlay {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.filter-modal-content {
  background: #ffffff;
  padding: 30px;
  border-radius: 20px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  border: 3px solid transparent;
  background-clip: padding-box, border-box;
  border-image-source: linear-gradient(135deg, #63b3ed, #2b6cb0);
  animation: fadeInModal 0.4s ease-out;
  position: relative;
}

@keyframes fadeInModal {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.filter-modal-content h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.8rem;
  color: #2a4365;
  border-bottom: 2px solid #e2e8f0;
  padding-bottom: 10px;
}

.filter-modal-content label {
  display: block;
  margin-bottom: 5px;
  font-size: 1rem;
  color: #4a5568;
  font-weight: 600;
}

.filter-modal-content input {
  width: 100%;
  padding: 12px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  margin-bottom: 15px;
  font-size: 1rem;
  background-color: #f7fafc;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.filter-modal-content input:hover {
  border-color: #3182ce;
  box-shadow: 0 0 8px rgba(49, 130, 206, 0.3);
}

.filter-modal-content input:focus {
  border-color: #2b6cb0;
  outline: none;
  box-shadow: 0 0 8px rgba(43, 108, 176, 0.5);
}

.filter-modal-actions {
  display: flex;
  justify-content:space-around;
  margin-top: 20px;
}

.filter-modal-actions button {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 12px 30px;
  font-size: 1rem;
  font-weight: bold;
  color: white;
  border: none;
  border-radius: 12px;
  background: linear-gradient(135deg, #63b3ed, #3182ce);
  box-shadow: 0 8px 15px rgba(49, 130, 206, 0.2);
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.3s ease, background 0.3s ease;
}

.filter-modal-actions button:first-child {
  background: linear-gradient(135deg, #4fd1c5, #2c7a7b);
}

.filter-modal-actions button:first-child:hover {
  background: linear-gradient(135deg, #38b2ac, #285e61); 
  box-shadow: 0 10px 20px rgba(56, 178, 172, 0.3);
  transform: translateY(-2px);
}

.filter-modal-actions button:last-child {
  background: linear-gradient(135deg, #f56565, #c53030); 
}

.filter-modal-actions button:last-child:hover {
  background: linear-gradient(135deg, #e53e3e, #9b2c2c); 
  box-shadow: 0 10px 20px rgba(229, 62, 62, 0.3);
  transform: translateY(-2px);
}

.filter-modal-actions button ion-icon {
  font-size: 1.5rem;
}

.filter-modal-actions button:active {
  transform: translateY(2px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

@media (max-width: 600px) {
  .filter-modal-content {
    padding: 20px;
    border-radius: 12px;
  }

  .filter-modal-content h2 {
    font-size: 1.5rem;
  }

  .filter-modal-actions button {
    font-size: 0.9rem;
    padding: 10px 15px;
  }
}

.applied-filters-modal {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #f9f9f9;
  border: 1px solid #e2e8f0;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 300px;
  border-radius: 12px;
  overflow: hidden;
  font-family: 'Arial', sans-serif;
  transition: height 0.3s ease-in-out;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  cursor: pointer;
  background-color: #edf2f7;
  border-bottom: 1px solid #e2e8f0;
}

.modal-header h3 {
  font-size: 16px;
  font-weight: bold;
  color: #2d3748;
  margin: 0;
}

.modal-header .toggle-icon {
  font-size: 18px;
  color: #a0aec0;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
}

.modal-header .toggle-icon:hover {
  color: #718096;
}

.modal-content {
  padding: 15px;
}

.applied-filters-modal ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.applied-filters-modal li {
  margin: 8px 0;
  font-size: 14px;
  color: #4a5568;
}

.applied-filters-modal .filter-label {
  font-weight: bold;
  color: #2d3748;
}

.clear-filters-button {
  display: block;
  width: 100%;
  padding: 10px 15px;
  background-color: #48bb78;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  transition: background-color 0.3s ease-in-out;
}

.clear-filters-button:hover {
  background-color: #38a169;
}

.clear-filters-button:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(56, 161, 105, 0.5);
}

.charts {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin: 40px auto;
  width: 100%;
}

.chart {
  background: linear-gradient(135deg, #ebf4ff, #e3e8f0);
  padding: 30px;
  border-radius: 16px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
}

.chart h2 {
  font-size: 1.8rem;
  margin-bottom: 20px;
  color: #2a4365;
}

.chart canvas {
  height: 400px;
  max-width: 90%; 
  width: 100%;
}

.chart-grouping-filter {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.chart-grouping-filter label {
  font-size: 1.2rem;
  color: #2a4365;
  margin-right: 10px;
  font-weight: 600;
}

.chart-grouping-filter select {
  padding: 10px 15px;
  font-size: 1rem;
  color: #2b6cb0;
  border: 2px solid #2c5282;
  border-radius: 8px;
  background-color: #edf2f7;
  transition: all 0.3s ease;
}

.chart-grouping-filter select:hover {
  border-color: #63b3ed;
  background-color: #ebf8ff;
}

.chart-grouping-filter select:focus {
  outline: none;
  border-color: #3182ce;
}

.active-users {
  margin-top: 40px;
}

.active-users h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.8rem;
  color: #2a4365;
}

.active-users table {
  width: 100%;
  border-collapse: collapse;
  background: #f7fafc;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.active-users th,
.active-users td {
  padding: 15px;
  text-align: center;
  font-size: 1rem;
  border-bottom: 1px solid #e2e8f0;
}

.active-users th {
  background-color: #2b6cb0;
  color: white;
  font-weight: 600;
}

.active-users td {
  color: #4a5568;
}

.active-users tbody tr:hover {
  background-color: #ebf8ff;
}
</style>

